.pricingPage {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.selectLocationInfoSection {
  width: 100%;
  min-height: 500px;
}

.locationInputSection {
  padding: 10px 0 60px 0;
  background-color: #00142e;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.locationInputSection h1 {
  margin-bottom: 40px;
  font-size: 44px;
  text-transform: uppercase;
}

.pricingPage .MuiAutocomplete-root {
  width: 480px !important;
}

.pricingPage .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 18 !important;
  background-color: white !important;
  border-radius: 30px !important;
}

.pricingPage .MuiInputLabel-root.Mui-focused,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  margin-top: -10px;
}

.pricingPage .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.pricingPage
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
  right: 22% !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 90px 7.5px 6px !important;
}

.locationViewButton {
  background-color: #00a0d2;
  position: absolute;
  bottom: 0;
  height: 56px;
  width: 100px;
  text-align: center;
  border-radius: 0 30px 30px 0;
  right: 0;
  cursor: pointer;
}

.inputArea {
  position: relative;
}

.locationViewButton p {
  margin-top: 18px;
}

.pricingArea {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pricingDetailSection {
  padding: 30px 0 40px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.carTypeOptions,
.serviceTypeOptions {
  display: flex;
  flex-direction: row;
}

.cardOption {
  padding: 20px;
  color: #b1b1b1;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}

.activeCard {
  padding: 20px;
  background-color: #00a0d2;
  color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}

.cardOptionImage {
  filter: sepia(0) saturate(0) brightness(75%) hue-rotate(180deg);
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 30px;
}

.activeCard .cardOptionImage {
  filter: brightness(0) invert(1);
}

.cardOptionTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

h2 {
  color: #001571;
  font-size: 28px;
  text-align: center;
  font-weight: 500;
}

.pricingSection p {
  color: #b1b1b1;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.toggleArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.firstTimeText {
  padding-top: 25px;
  margin-right: 20px;
}

.secondTimeText {
  padding-top: 25px;
  margin-left: 20px;
}

.pricingSection {
  width: 100%;
}

.pricingSection p.textActive {
  color: #00a0d2;
}

.pricingGrid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 70px;
}

.pricingCard {
  align-items: center;
  border: 1px solid #00a0d2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: 200px;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 300px;
}

.pricingCardHeading {
  margin: 0;
  margin-bottom: 10px;
}

.pricingCardText,
.pricingCardPOA {
  color: #00a0d2;
  margin: 0;
  font-weight: 500;
}

.bookNowButton {
  background-color: #00a0d2;
  padding: 10px;
  color: white;
  margin-top: 30px;
  margin-bottom: 50px;
  min-width: 150px;
  text-align: center;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
}

.pricingCardText::before {
  content: "From ";
}

.originalPrice {
  color: #a9a9a9;
  text-decoration: line-through;
}

.pricingSection p.smallDesc {
  color: #001571;
}

.pricingSection p.includedText {
  margin: 0;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  padding-left: 15px;
  color: #00a0d2;
}

.includedItemsList {
  width: 80%;
  padding-left: 15px;
  margin-top: 10px;
}

.includedItem {
  color: #9c9c9c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.optionalExtrasSection {
  width: 100%;
  min-height: 320px;
  background-color: #f8f8f8;
  padding: 30px 0;
}

.optionalExtrasSection h2 {
  text-align: center;
}

.extrasSectionArea {
  max-width: 700px;
  margin: auto;
}

.extrasSectionList {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-top: 60px;
}

.extraItems {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .pricingCard {
    min-width: 310px;
  }
  .extrasSectionList {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .extrasSectionArea {
    max-width: 800px;
    margin: 0 auto;
    width: 320px;
  }
}

@media (min-width: 768px) {
  .pricingGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .pricingGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .pricingGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}
