.notFoundPage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.notFoundPageLogo{
    width: 280px;
    margin-bottom: 20px;
}

.notFoundHeading{
    margin-bottom: 20px;
    color: #001571;
}

.notFoundSubHeading{
    margin-bottom: 15px;
    color: #00A0D2;
}

.helpLinkButton{
    text-decoration: none;
    color: white;
    background-color: #001571;
    padding: 20px;
    margin-bottom: 10px;
    width: 290px;
    text-align: center;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #001571;
}

.helpLinkButton:hover{
    text-decoration: none;
    background-color: white;
    color: #001571;
}