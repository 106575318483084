.prepaidBanner{
    background-color: #00142E;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    text-transform: uppercase;
    min-height: 50px;
}

.displayCenter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bundleContainer{
    max-width: 750px;
    margin: auto;
}

.bundlesGrid{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.bundleItem{
    width: 44%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid #00142E;
    padding: 0px 0px 25px 0px;
    cursor: pointer;
    position: relative;
}

.singleBundleItem{
    width: 100%;
}

.bundleItem p{
    margin: 0;
    z-index: -1;
}

.bundleImage{
    width: 100%;
    height: 170px;
    object-fit: cover;
    z-index: -1;
}

.singleBundleItem .bundleImage{
    height: 220px;
}

.bundleTitle{
    margin-top: 20px;
    z-index: -1;
}

.bundleDesc{
    z-index: -1;
    color: #2196F3;
}

.selectedItem{
    border-color: #2196F3;
    border-width: 3px;
}

.bundleSelect{
    position: absolute;
    top: -16px;
    background: #2196F3;
    color: white;
    font-weight: 600;
    padding: 5px 15px;
    border-radius: 100px;
}

.purchaseButtonSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    text-align: center;
}

.purchaseButton{
    border: none;
    outline: none;
    background-color: #00142E;
    color: #FFF;
    font-weight: 600;
    padding: 10px;
    border-radius: 100px;
    font-size: 22px;
}

.errorText{
    color: red;
}

.userInputs{
    width: 100%;
}

.userInputs select{
    margin-top: 35px;
    height: 50px;
    border-color: #00142E;
    color: #00142E;
    font-weight: 600;
    padding-left: 15px;
    width: 100%;
}

.userInputs input{
    margin-top: 15px;
    margin-bottom: 35px;
    height: 50px;
    border: 1px solid #00142E;
    color: #00142E;
    font-weight: 600;
    padding-left: 15px;
    width: 100%;
}

.noBundles{
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


