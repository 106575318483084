.maintenanceTitle{
    text-align: center;
    justify-self: center;
    align-self: center;
    max-width: 800px;
}

.maintenancePage{
    padding: 10px 0 60px 0;
    background-color: #00142E;
    width: 100%;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: center;
}