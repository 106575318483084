.titleSection h1{
    color: #001571;
}

.updateBookingContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 700px;
    margin: auto;
}

.updateBookingContainer .flexCol{
    margin-left: 5px;
    margin-right: 5px;
    flex-direction: column; 
    display: flex;
}

.updateBookingContainer .flexCol:first-child{
    align-items: flex-start;
    width: 50%;
    padding-right: 0px;
    margin-right: 80px;
}

.updateBookingContainer .flexCol:last-child{
    align-items: flex-start;
    width: 50%;
}

.flexCol h5{
    color:#001571;
    font-weight: 600;
    margin: 0;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.updateBookingContainer input{
    width: 80%;
    border: 1px solid #001571;
}

.updateCell {
    width: 100%;
    margin-bottom: 20px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    height: 60px;
}

.bookingText{
    color: #00A0D2;
    font-weight: 400;
    margin: 0;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.updateCell .MuiButton-root.bookingTimeButton {
    width: 80%;
    border: 1px solid #001571;
    padding-top: 10px;
    padding-bottom: 10px;
}
.updateCell .MuiButton-root > img {
    margin-left: 10px;
}

.headings .updateCell:last-child{
    justify-content: flex-end;
}

.checkModalContainer{
    position: absolute;
    background: rgba(0,0,0,0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
}

.checkModalContainer .checkModalCard{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    min-width: 40%;
    min-height: 20%;
    text-align: center;
    padding: 30px;
}

.checkModalContainer .checkModalCard .buttonSection{
    margin-top: 40px;
}

.checkModalContainer .checkModalCard .modalButton{
   margin-left: 15px;
   margin-right: 15px;
}

.searchButtonSection{
    width: 100%;
    text-align: center;
}

.manageBookingButtons{
    width: 50%;
    margin: auto;
}

@media(max-width:820px) {
    .updateBookingContainer{
        flex-wrap: wrap;
        padding: 0 16px;
    }
    .updateBookingContainer .flexCol:first-child, .updateBookingContainer .flexCol:last-child{
        width: 100%;
        margin: 0;
    }

    .headings .updateCell:last-child{
        justify-content: center
    }
    .updateCell{
        justify-content: center;
        margin: 0;
    }
}
@media(max-width: 767px) {
    .manageBooking {
        padding-bottom: 210px;
    }
    .updateBookingContainer .flexCol {
        width: 100%;
        margin: 0;
    }
    .manageBookingTitle h1 {
        line-height: 48px;
    }
    .headings .updateCellLeft:last-child{
        justify-content: flex-start;
        height:auto;
    }
    .updateCellLeft{
        margin: 0;
        justify-content: flex-start;
    }
    .updateBookingTimeWrapper {
        width: 100%;
    }
    .updateBookingContainer input {
        width: 100%;
        border: none;
        outline: none;
    }
    .updateCell .MuiButton-root.bookingTimeButton {
         width: 100%;
         justify-content: space-between;
         padding: 10px 15px;
         border:none;
         background-color: #F8F8F8;
    }
    .updateCell .MuiButton-root.bookingTimeButton span{
        font-weight: 400;
        text-transform: none;
   }
    .bookingText {
        height: auto;
    }
    .bookingTextMobile {
        height: auto;
    }
    .fixedButton {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 33px 16px;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
        display: flex;
        justify-content: center;
        align-items: center;
        gap:10px;
        background-color: #ffffff;
        z-index: 11;
    }
    .checkModalContainer .checkModalCard {
        padding: 30px 15px;
    }
    .checkModalContainer .checkModalCard .buttonSection{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap:10px;
    }
    .manageBookingButtons{
        width: 100%;
        margin: auto;
        flex-direction: column-reverse;
    }

    .manageBookingButtons .searchButtonSection button{
        width: 80%;
        margin: auto;
        margin-top: 20px;
    }
}
@media(max-width:360px) {
    .fixedButton {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap:10px;
    }
    .fixedButton > button {
        width: 100%;
    }
}