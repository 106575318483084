.bookingFlowSection {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 0 25px;
}

.bookingFlowSection .pricingPage {
  flex: auto;
}

.bookingFooter {
  background-color: #f8f8f8;
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 80px;
  z-index: 111;
}

.bookingBanner {
  background-color: #00142e;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-transform: uppercase;
}

.bookingHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.logoSection {
  width: 20%;
}

.closeIcon {
  width: 30px;
}

.titleSection {
  text-align: center;
  width: 60%;
  padding-top: 30px;
}

.titleSection h1 {
  font-size: 44px;
  text-transform: uppercase;
}

.closeButtonSection {
  width: 20%;
  text-align: right;
  padding-right: 20px;
}

.lightBlueText {
  color: #00a0d2;
}

.bookingProgressSection {
  flex: 0.35;
  margin: 25px;
}

.stepSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #b8b9bf;
  min-height: 44px;
}

.activeStep {
  color: #001571;
}

.completedStep {
  color: #00a0d2;
}

.bookingStep {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookingStepArea {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookingStep:not(:first-child)::before {
  content: "";
  background: url("../../assets/images/rightArrow.svg") no-repeat center;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.summaryBox {
  border: 2px solid #001571;
  margin: auto;
  border-radius: 12px;
  min-height: 500px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 25px;
  min-width: 350px;
  max-width: 480px;
  position: sticky;
  top: 15px;
}

.summaryBox h2 {
  text-align: left;
  margin: 0;
  margin-bottom: 15px;
}

.summaryBox .progressBarSection {
  margin-bottom: 20px;
}

.summaryBox p {
  color: #b8b9bf;
  margin-bottom: 10px;
}

.summaryBox p.note {
  color: #8a8a8b;
}

.stepSections {
  flex: 0.65;
}

.loginSection {
  margin-bottom: 50px;
}

.loginSection h3,
.locationDetailSection h3 {
  color: #001571;
  font-size: 16px;
  margin-bottom: 25px;
}

.loginSection .lightBlueButton {
  margin-right: 20px;
}

.locationDetailSection {
  display: flex;
  flex-direction: column;
  max-width: 450px;
}

.locationDetailSectionOffline h3,
.locationDetailSectionOffline .inputArea,
.locationDetailSectionOffline .carTypeOptions,
.locationDetailSectionOffline .bookingTimeSection,
.footerOffline .buttonSection {
  opacity: 0.3;
}

.locationDetailSectionOffline::after,
.footerOffline::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 40px !important;
}

.MuiAutocomplete-root {
  width: auto !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 24% !important;
}

.MuiOutlinedInput-root {
  border-radius: 30px !important;
}

.MuiInputLabel-root.Mui-focused,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #001571 !important;
  margin-top: 0;
}

.locationViewButton p {
  color: white;
  font-weight: 600;
}

.locationDetailSection {
  position: relative;
}

.locationDetailSection h3 {
  margin-bottom: 40px;
}

.locationDetailSection .inputArea {
  margin-bottom: 50px;
}

.locationDetailSection .cardOptionImage {
  margin-bottom: 0;
  filter: sepia(0) saturate(0) brightness(0%) hue-rotate(180deg);
  opacity: 0.3;
}

.locationDetailSection .activeCard .cardOptionImage {
  filter: brightness(0) invert(1);
  opacity: 1;
}

.summaryDetailText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summaryBox .darkBlueText {
  color: #001571;
  font-size: 14px;
  font-weight: 500;
}

.summaryBox .lightBlueText {
  color: #00a0d2;
  font-size: 14px;
  font-weight: 500;
}

.summaryDetailText .lightBlueText {
  width: 50%;
  text-align: right;
  overflow-wrap: anywhere;
}

.yourDetailSection h3 {
  color: #001571;
  font-size: 19px;
}

.yourDetailSection h3.servicesSectionHeading {
  margin-top: 20px;
}

.bookingTimeSection .MuiButton-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  padding: 10px;
  border: 2px solid #001571;
}

.bookingTimeSection h3 {
  margin-top: 50px;
}

.bookingTimeSection .bookingNote1 {
  font-size: 14px;
  color: #303030;
}

.bookingTimeSection .bookingNote2 {
  font-size: 14px;
  color: #00a0d2;
  margin-top: 30px;
}

.bookingTimeButton span {
  color: #001571;
}

.modalTitle {
  color: #00a0d2;
  margin: 0;
  font-size: 20px;
}

.modalDesc {
  text-align: center;
  font-size: 14px;
  color: #303030;
  margin: 30px auto 10px auto;
  max-width: 500px;
}

.bundleModal .modalTitle {
  color: #001571;
  font-size: 31px;
  font-weight: 500;
}

.bundleModal .modalDesc {
  color: #00a0d2;
  font-size: 31px;
  font-weight: 500;
}

.bundleModal .bundleName {
  color: #001571;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.bundleModal .bundlePrice {
  color: #00a0d2;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.bundleModal .bundleImageSection {
  width: 100%;
  text-align: center;
}

.bundleModal .bundleImage {
  width: 130px;
  height: 150px;
  filter: invert(47%) sepia(93%) saturate(2124%) hue-rotate(162deg)
    brightness(92%) contrast(101%);
}

.bundleModal .buttonSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 500px;
  margin: 100px auto 10px auto;
}

.bundleModal .blueBorderButton,
.bundleModal .lightBlueButton {
  width: 220px;
}

.rewardModal .modalTitle {
  color: #00142e;
  font-size: 34px;
  font-weight: 600;
}

.rewardModal .modalDesc {
  color: balck;
  font-size: 26px;
  font-weight: 500;
  max-width: 520px;
}

.rewardModal .buttonSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 500px;
  margin: 10px auto 10px auto;
}

.rewardModal .rewardModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 470px;
  padding-top: 50px;
}

.rewardModal .blueBorderButton,
.rewardModal .lightBlueButton {
  width: 220px;
}

.rewardModal .noteText {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.calendarTimeSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: flex-start;
  align-items: "flex-start";
  overflow-y: scroll;
  height: 55vh;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.calendarTimeSection::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.calendarArea {
  width: 100%;
  justify-content: center;
}

.timeArea {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 90%;
}

.calendarTimeSection h4 {
  text-align: center;
  color: #001571;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sdp--text.sdp--month-name {
  color: #00a0d2;
}

.sdp--square-btn__shadowed,
.sdp--square-btn__shadowed:focus:hover {
  color: #00a0d2;
  width: 2rem;
  height: 2rem;
}

.sdp {
  --selected-date-color: #eeeeee !important;
  --theme-color: #00a0d2 !important;
  --font: "Poppins", sans-serif !important;
  box-shadow: none !important;
  width: 19rem !important;
  margin: auto;
}

p.sdp--text,
button.sdp--square-btn.sdp--text {
  color: #00142e;
}

button.sdp--square-btn.sdp--text.sdp--text__inactive {
  color: rgb(206, 206, 206);
}

button.sdp--square-btn.sdp--text.sdp--date-btn__selected {
  color: white;
}

.timeOptionBox {
  margin-top: 20px;
  width: 50%;
  display: flex;
  flex-grow: 1;
}

.timeOptions p.afterNoonDiscount {
  background-color: #e5f5fb;
  color: #00a0d2;
  padding: 3px 15px;
  text-align: left;
  width: fit-content;
  font-weight: 500;
  border-radius: 100px;
  margin: 0;
  font-size: 11px;
  margin-left: 0px;
  margin-top: 3px;
}

.timeOptionBox.selectedTime .timeOptions {
  border: 1px solid #00a0d2;
}

.timeOptions {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  cursor: pointer;
  justify-content: center;
}

.timeOption1,
.timeOption2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.timeOption1 img,
.timeOption2 img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 1px solid #a9a9a9;
}

.timeOption1 .check {
  width: 20px;
  height: 20px;
  border: 1px solid #a9a9a9;
  border-radius: 50%;
}
.timeOption1 .check img {
  margin-right: 0;
  object-fit: cover;
  vertical-align: unset;
  visibility: hidden;
}
.timeOption1 .isActive {
  border: 1px solid transparent;
}
.timeOption1 .isActive img {
  visibility: visible;
}
.bookingFooter .buttonSection {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bookingFooter .buttonSection button {
  margin: 0 10px;
}

.timeOptions h5 {
  color: #9c9c9c;
  margin: 0;
  text-align: center;
  margin-bottom: 5px;
}

.timeOptions p {
  margin: 0;
  font-size: 12px;
  margin-left: 10px;
}

.noBookingAvailableText {
  color: #9c9c9c;
}

.summaryDetailText .lightBlueText.leftAlign {
  text-align: left;
}

.bookingPriceGrid {
  margin: 70px auto;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.serviceStep h3 {
  color: #001571;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}

.bookingPriceGrid .pricingCard {
  position: relative;
  border-color: #9c9c9c;
}

.bookingPriceGrid .pricingCard.selectedService {
  border-color: #00a0d2;
  border-width: 2px;
}

.bookingPriceGrid .pricingCard h2 {
  margin: 0;
}

.bookingPriceSelect {
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 150px;
  text-align: center;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}

.bookingPriceGrid .bookNowButton {
  margin-bottom: 0;
  width: 175px;
}

.serviceDurationSection {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.serviceDuration {
  margin-left: 12px;
  margin-bottom: 0;
}

.durationIcon {
  width: 18px;
}

.bookingPriceGrid .includedText {
  align-self: flex-start;
  font-size: 18px;
  font-weight: 500;
}
.errorMess {
  color: red;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.serviceStep .cardOptionImage {
  width: auto;
}

.mostPopular::before {
  content: "Most Popular";
  position: absolute;
  top: -12px;
  background-color: #00a0d2;
  padding: 5px 40px;
  color: white;
  font-weight: 600;
  border-radius: 4px;
}

.extraStep {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}
.extraContentWrapper {
  display: flex;
  align-items: center;
}
.extraExamAndPrice {
  display: flex;
  align-items: center;
}
.extraStep h2 {
  text-align: left;
  margin-bottom: 30px;
}

.extraStep h2.notFirstHeading {
  margin-top: 30px;
}

.extraStep .extraItem {
  display: flex;
  flex-direction: row;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  cursor: pointer;
}

.extraItem div.extraName {
  padding: 0 20px;
  min-width: 290px;
}

.extraItem div.extraExample {
  min-width: 150px;
  z-index: 111;
}

.extraPrice {
  color: #00a0d2;
  font-weight: 500;
}

.extraItem p {
  margin: 0;
}
.extrasExampleButton {
  color: #001571 !important;
  text-transform: none !important;
}

.extraExampleHeadings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.extraExampleHeadings .extraName {
  color: #001571;
}

.exampleImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-top: 20px;
}

.extraItem.activeItem {
  border-color: #00a0d2;
}

.extraExampleButtonSection {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.extraOptionTitle {
  color: #001571;
  font-size: 18px;
  font-weight: 600;
}

.durationFrom {
  font-size: 14px;
  color: #b8b9bf;
}

.durationFrom::before {
  content: "From ";
}

.durationSection {
  display: flex;
  flex-direction: row;
}

.durationSection .durationIcon {
  width: 12px;
  margin-right: 5px;
}

.uncheckedIcon {
  width: 20px;
  height: 20px;
  filter: brightness(0);
}

.checkIcon {
  width: 20px;
  height: 20px;
}

.checkoutBottomSection {
  margin-top: 45px;
  margin-bottom: 30px;
}

.giftCardSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 50px;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  padding: 20px 20px;
}
.giftCardSection .flex-row {
  flex-wrap: wrap;
}

.giftCardCheckBox {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.giftCardHeading {
  cursor: pointer;
  color: #001571;
}

.giftCardCheckBox.unchecked {
  filter: brightness(0);
}

.subsriptionModal .extraExampleHeadings {
  justify-content: center;
}

.subsriptionModal .subImage {
  width: 100%;
  height: 80px;
  object-fit: contain;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subsriptionModal .extraPrice {
  text-align: center;
  font-size: 30px;
  margin-bottom: 50px;
}

.modalLightText {
  color: #9c9c9c;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  margin: 0 20px;
  margin-bottom: 50px;
}

.confirmationStep h2 {
  text-align: left;
}

.bookingTimeSection {
  justify-content: space-between;
  max-width: 100%;
}

.bookingTimeSection .lightBlueButton {
  height: 40px;
  padding: 0;
  width: 115px;
}

.bookingTimeSection .lightBlueButton:hover {
  padding: 0;
}

.confirmationStep .bookingTimeSection {
  max-width: 60%;
}

.bookingTimeSelect {
  color: #00a0d2;
  font-size: 18px;
  font-weight: 500;
}

.bookingTimeTextCheck {
  font-weight: 600;
  margin: 0;
}

.confirmationHeadings {
  margin-top: 40px;
}

.aboutYouSection {
  margin-top: 30px;
  max-width: 80%;
}

.twoColumn,
.oneColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-right: 15px;
  margin-bottom: 10px;
}

.confirmSection {
  max-width: 80%;
}

.confirmSection .buttonSection {
  margin-top: 30px;
  margin-bottom: 60px;
}

.confirmSection .buttonSection .lightBlueButton {
  margin-left: 30px;
}

.bookingSuccessPage {
  text-align: center;
}

.bookingSuccessPage .bookingBanner {
  padding: 20px;
}

.bannerLogo {
  width: 150px;
}

.bookingSuccessPage h1 {
  color: #00a0d2;
  padding-top: 10px;
}

.bookingSuccessPage .successTick {
  padding-top: 40px;
  width: 80px;
}

.successContent {
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
}

.successContent p.note {
  font-size: 14px;
}

.bookingNumber {
  margin-top: 20px;
  margin-bottom: 30px;
  color: #001571;
}

.subsctriptionHeading {
  color: #001571;
  margin-top: 80px;
}

.listedSubs ul {
  text-align: left;
  width: 50%;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 20px;
  font-size: 20px;
  color: #00142e;
}

.subscriptionSection a:hover {
  color: white;
}

.subscriptionSection a {
  text-decoration: none;
  padding: 10px 50px;
}

.giftCardAmountSection {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.giftCardAmountSection p {
  margin: 0;
}

.summaryDetailText .lightBlueText.largerTextWidth {
  width: 65%;
}

.giftCardAmount {
  width: 93.18px;
  padding: 8px;
  background: #00a0d2;
  border-radius: 4px;
  text-align: center;
  color: white;
}

.giftCardAmountInput {
  flex-direction: row;
  display: flex;
  align-items: center;
  position: relative;
  width: 78%;
}

.giftCardAmountInput .amountDollar {
  position: absolute;
  left: 10px;
}

.giftCardAmountInput #giftCardAmount {
  padding-left: 25px;
  width: 100%;
}

.subscriptionSection .giftCardAmount {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.giftCardNumber {
  color: #00a0d2;
}

.noValue {
  color: rgb(183, 183, 183);
}

.modalFooter {
  background: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  padding: 10px;
}

.selectButton {
  background-color: #00a0d2 !important;
  padding: 5px 0 !important;
  width: 125px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  border-radius: 40px !important;
  color: white !important;
  cursor: pointer !important;
  border: 1px solid #00a0d2 !important;
}

/* Header Mobile */
.headerMobile {
  width: 100%;
}
.headerMobile .headerMobileBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 9px 13px 9px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.headerMobile .headerMobileBottom img {
  width: auto;
  height: 37px;
}

.loggedInSection {
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  max-width: 400px;
  border-radius: 8px;
}

.profileSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userProfilePic {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.profileText {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.notFoundPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.notFoundPageLogo {
  width: 280px;
  margin-bottom: 20px;
}

.timeOptionBox p .optionNote {
  color: #00a0d2;
  font-weight: 500;
  font-size: 13px;
  line-height: 19.5px;
  text-transform: capitalize;
}

.timeOptionBox p .optionNote.unavailable {
  color: #b8b9bf;
}

.cancelledText {
  margin-top: 50px;
  text-align: center;
  max-width: 70%;
}

.cancelledText h1 {
  color: #001571;
}

.cancelledText p {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #00a0d2;
}

.subUrlButton {
  margin-top: 30px;
}

.backToWebsite,
.successButtonSection {
  margin-top: 50px;
}

.backToWebsite .lightBlueButton {
  text-decoration: none;
  padding: 14px 70px;
  font-size: 16px;
}

.backToWebsite .lightBlueButton:hover {
  color: white;
}

.failContent {
  margin-top: 30px;
}

.discountBubble {
  margin: auto;
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 10%);
  border-radius: 50px;
  height: 55px;
  position: sticky;
  top: 10px;
  z-index: 1111;
  width: 90%;
}

.gotDiscount {
  background: #00a0d2;
}

.bubbleText {
  color: #00a0d2;
  font-weight: 500;
  font-family: "Poppins";
  margin: 0;
}

.bubbleTextWhite {
  color: white;
  font-weight: 500;
  font-family: "Poppins";
  margin: 0;
}

.bubbleRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.discountTick {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.animated-progress {
  width: 90%;
  height: 5px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #e5e5e5;
  margin: auto;
  margin-top: 5px;
}

.animated-progress span {
  height: 100%;
  display: block;
  width: 0%;
  color: rgb(255, 251, 251);
  line-height: 30px;
  position: absolute;
  text-align: end;
  padding-right: 5px;
  background-color: #00a0d2;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.desktopProgress {
  height: 10px;
  border-radius: 0px;
  margin-left: 0;
  margin-top: 0;
}

.summaryBox .added-coupon {
  display: flex;
  align-items: center;
  color: #2bb800;
}
.green-tick {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.green-tick img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newServiceModal .modalTop {
  position: relative;
}

.newServiceModal .modalTopImage {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.newServiceModal .modalCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
}

.newServiceModal .modalClose {
  width: 30px;
}

.newServiceModal .modalClose img {
  filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg);
}

.newServiceModal .modalBottom {
  padding: 40px;
}

.newServiceModal .modalTitle {
  font-size: 16px;
  margin: 0;
}

.newServiceModal .modalDesc {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.newServiceModal .noteText {
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
}

.newServiceModal .buttonSection {
  margin-left: 30px;
  margin-right: 30px;
}

.newServiceModal .lightBlueButton {
  width: 100%;
}

.watingMessage {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.extraItemContainer {
  display: flex;
  flex-direction: column;
}

.subItemTerms {
  margin-bottom: 20px;
  margin-left: 15px;
}

.subItemTermsText {
  font-size: 14px;
  align-content: center;
}

.subItemTermsText input {
  height: auto;
}

.lightBlueButton:disabled {
  opacity: 0.5;
}

.termsLink {
  color: #00a0d2;
  cursor: pointer;
}

.poaNoteText {
  color: #001571 !important;
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
}

@media (max-width: 1700px) {
  .bookingPriceGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .bookingPriceGrid .pricingCard {
    max-width: 300px;
  }
}

@media (max-width: 820px) {
  .closeButtonSection {
    display: none;
  }
  .bookingHeader {
    padding: 0 14px;
  }
  .logoSection {
    display: none;
  }
  .bookingBanner {
    display: none;
  }

  .titleSection {
    text-align: center;
    width: 100%;
    padding-top: 30px;
  }
  .bookingStep:not(:first-child)::before {
    display: none;
  }

  .bookingStep {
    width: 76px;
    height: 6px;
    margin-left: 0;
    margin-right: 12px;
    background-color: #e5e5e5;
  }
  .bookingStep:last-child {
    margin-right: 0;
  }
  .activeStep {
    background-color: #00a0d2;
  }
  .completedStep {
    background-color: #00a0d2;
  }
  .bookingStep p {
    display: none;
  }
  .bookingFlowSection {
    flex-direction: column;
    padding: 0 14px;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 28% !important;
  }
  .titleSection h1 {
    font-size: 32px;
    line-height: 16px;
    font-weight: 600;
    font-family: "Poppins";
    text-transform: uppercase;
  }
  .bookingPage .cardOptionTitle {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }
  .bookingPage .cardOptionImage {
    width: 60px;
    height: 50px;
    margin: 0;
    margin-bottom: 15px;
  }
  .bookingPage .cardOption {
    padding: 20px 0;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 0px;
  }
  .bookingPage .activeCard {
    padding: 20px 0;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 0px;
  }
  .bookingTimeSection {
    width: 100%;
    max-width: 600px;
  }
  .bookingTimeSection h3 {
    margin-top: 32px;
    font-size: 28px;
    font-weight: 16px;
    margin-bottom: 26px;
  }
  .bookingFooter {
    background-color: #ffffff;
    width: 100%;
    position: relative;
    bottom: 0;
    height: auto;
    z-index: 111;
    padding: 20px 14px;
  }
  .bookingFooter .buttonSection {
    display: flex;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    justify-content: center;
    gap: 9.5px;
  }
  .bookingFooter .buttonSection button {
    margin: 0;
  }
  .bookingProgressSection {
    margin: 0;
    position: relative;
    margin-bottom: 19px;
  }
  .bookingProgressSection .summaryBox {
    padding: 18px;
    min-height: unset;
    transition: all 0.5s;
    min-width: auto;
  }
  .bookingProgressSection .summaryBox h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    line-height: 16px;
    margin-bottom: 0;
  }
  .bookingProgressSection .summaryBox h2 .rotate {
    transition: all 0.5s;
    transform: rotate(180deg);
  }
  .stepSections {
    padding-bottom: 0;
  }
  .confirmStep {
    padding-bottom: 30px;
  }
  .extraStep {
    max-width: unset;
    width: 100%;
  }
  .extraItem div.extraExample {
    min-width: 130px;
  }
  .extraContentWrapper {
    width: 100%;
    justify-content: space-between;
  }
  .durationSection .durationFrom::before {
    display: none;
  }
  .extraItem .extrasExampleButton {
    padding: 0;
  }
  .giftCardSection .flexRow {
    flex-wrap: wrap;
  }
  .giftCardSection .flexRow .twoColumn {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .giftCardSection .flexRow .oneColumn {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .confirmSection {
    max-width: unset;
    width: 100%;
  }
  .confirmSection p {
    width: 80%;
  }
  .aboutYouSection {
    margin-top: 30px;
    max-width: unset;
    width: 100%;
  }
  .aboutYouSection .flexRow {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .aboutYouSection .flexRow .twoColumn {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .aboutYouSection .flexRow .oneColumn {
    margin-right: 0;
  }
  .confirmationButton {
    display: flex;
    justify-content: center;
  }
  .mobileMenu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #30303082;
    width: 100%;
    height: 100%;
    z-index: 1111111;
  }
  .mobileNavSection {
    background-color: white;
    width: 82%;
    height: 100%;
    flex-direction: column;
    text-align: left;
    padding: 50px 30px;
  }
  .mobileNavItem {
    margin-bottom: 40px;
  }

  .mobileNavItem a {
    text-decoration: none;
    color: #001571;
    font-size: 14px;
    font-weight: 600;
  }

  .sdp--dates-grid {
    gap: 3px;
  }
  .sdp--square-btn__shadowed {
    overflow: auto;
  }
  .sdp--square-btn__shadowed,
  .sdp--square-btn__shadowed:focus:hover {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .sdp--square-btn__shadowed svg {
    width: 17px;
    height: 17px;
  }
  .sdp--text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  .sdp--month-name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  .sdp--date-btn {
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    width: 41px;
    height: 41px;
    border-radius: 4px;
  }
  .modalDesc {
    max-width: 238px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 30px;
  }
  .calendarTimeSection {
    height: 50vh;
    overflow-y: hidden;
    flex-direction: column;
  }
  .calendarTimeSection h4 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
  }
  .sdp--month-picker {
    margin-bottom: 15px;
  }
  .bookingCalendar {
    padding: 0;
  }
  .selectButtonMobile {
    background-color: #00a0d2 !important;
    padding: 5px 0 !important;
    width: 100% !important;
    max-width: 420px;
    height: 45px;
    font-weight: 600 !important;
    font-size: 16px !important;
    border-radius: 40px !important;
    color: white !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px;
  }
  .dateTimeStep {
    width: 100%;
    height: 8px;
    border-radius: 7px;
    background: #f2f2f2;
    position: relative;
  }
  .dateTimeStep1::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #00a0d2;
    border-radius: 7px;
  }
  .dateTimeStep2::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00a0d2;
    border-radius: 7px;
  }
  .closeButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 9px;
    padding: 0 10px;
  }
  .closeButton img {
    width: 16px;
    height: 16px;
  }
  .popupModalDateTime .MuiBox-root {
    padding: 2px;
    outline: none;
  }
  .selectedDate {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    width: 100%;
    max-width: 271px;
    height: 36px;
    margin: 0 auto;
    background-color: #f2f2f2;
    border-radius: 24px;
    margin-top: 10px;
  }
  .selectedDate p {
    color: #00a0d2;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    font-weight: 600;
  }
  .contactLink {
    color: #00a0d2;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    font-weight: 600;
  }
  .timeArea {
    flex-direction: column;
    padding: 0 15px;
    max-height: 400px;
    overflow-y: scroll;
    padding-bottom: 120px;
    flex-wrap: nowrap;
  }
  .timeArea::-webkit-scrollbar {
    width: 6px;
    background-color: #e4e4e4;
    border-radius: 10px;
  }
  .timeArea::-webkit-scrollbar-thumb {
    background-color: #00a0d2;
    border-radius: 10px;
  }
  .timeOptions {
    width: 100%;
    padding: 6px;
  }
  .timeOption1 {
    margin: 0;
  }
  .calendarTimeSection .selectTimeTitle {
    margin-bottom: 14px;
  }
  .timeOptionBox {
    width: 100%;
    margin-right: 0;
    margin-top: 7px;
  }

  .timeOptionBox p {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    color: #a9a9a9;
    font-weight: 500;
  }
  .loginSection {
    text-align: center;
  }
  .bookingFlowSection {
    min-height: 65vh;
  }
  .bookingTimeSection .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    border: 2px solid #001571;
  }
  .bookingPriceGrid .includedText {
    align-self: center;
  }
  .bookingPriceGrid .includedItemsList {
    text-align: center;
    width: fit-content;
  }
  .bundleModal .buttonSection {
    flex-direction: column-reverse;
    max-width: unset;
    width: 100%;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
  }
  .bundleModal .buttonSection button {
    width: 100%;
  }
  .bundleModal .modalTitle {
    font-size: 24px;
  }
  .bundleModal .bundleName {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .bundleModal .bundlePrice {
    font-size: 18px;
  }
  .bundleModal .modalDesc {
    font-size: 16px;
    line-height: 18px;
  }
  .newServiceModal .modalDesc {
    margin: 30px auto 10px;
    font-size: 18px;
  }
  .newServiceModal .modalBottom {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .confirmationStep .bookingTimeSection {
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .bookingTimeSection .lightBlueButton {
    width: 100%;
  }

  /* Prepaid Card Page */
  .prepaidCardPage .bookingHeader h1 {
    line-height: normal;
  }

  .prepaidContainer .prepaidUserDetails .flexRow {
    flex-direction: column;
  }

  .prepaidContainer .prepaidUserDetails .twoColumn {
    margin-bottom: 10px;
  }

  .prepaidContainer .prepaidUserDetails .oneColumn {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 30% !important;
  }
  .bookingPriceGrid {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
  }
  .bookingPriceGrid .pricingCard {
    min-width: unset;
    width: 100%;
    max-width: unset;
  }
  .bookingPriceGrid .pricingCard {
    min-width: unset;
    width: 100%;
    max-width: unset;
    padding-bottom: 0;
    margin: 0 0 15px 0;
    min-height: 350px;
  }
  .bookingPriceGrid .pricingCard .pricingCardHeading {
    margin-top: 10px;
  }
  .bookingPriceGrid .pricingCard .bookingPriceSelect {
    margin-top: 15px;
  }

  .bookingPage .extraStep {
    max-width: unset;
    width: 100%;
  }
  .bookingPage .extraContentWrapper {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
  }
  .bookingPage .extraItem div.extraName {
    padding: 0;
    display: flex;
    min-width: unset;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }
  .extraExamAndPrice {
    justify-content: space-between;
    width: 100%;
  }
  .durationSection {
    align-items: center;
  }
  .durationSection::before {
    display: none;
  }
  .extraOptionTitle {
    font-weight: 500;
  }
  .confirmSection p {
    width: 100%;
  }
  .confirmSection .buttonSection {
    width: 100%;
  }
  .loginSection .buttonSection button {
    width: 100%;
    margin-top: 10px;
  }
  .twoColumn {
    margin: 0;
  }
  /* .timeOptions{
        width: 95%;
    } */
  .rewardModal .rewardModalContent {
    min-height: max-content;
    padding-top: 0;
  }
}
@media (max-width: 375px) {
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 31% !important;
  }
  .extraStep .extraItem {
    padding: 12px;
  }
}
@media (max-width: 360px) {
  .bookingStep {
    width: 50px;
  }
}

@media (max-width: 1140px) {
  .extraItem div.extraName {
    min-width: auto;
  }
}

@media (max-width: 950px) and (min-width: 768px) {
  .extraStep {
    max-width: 95%;
  }
}
