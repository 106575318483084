.prepaidBanner {
  background-color: #00142e;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-transform: uppercase;
  min-height: 50px;
}

.displayCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sizeContainer {
  margin-top: 30px;
}

.inputsSection {
  width: 100%;
  position: relative;
}

.prepaidSelect,
.prepaidSelect * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
.prepaidSelect {
  position: relative;
  color: #00a0d2;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #00a0d2;
  border-radius: 8px;
  width: 250px;
  outline: none;
}
.prepaidSelect select {
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.prepaidSelect select:active,
.prepaidSelect select:focus {
  outline: none;
  box-shadow: none;
}
.prepaidSelect:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.selectedServiceSection {
  margin-top: 50px;
}

.selectedSericeText {
  color: #001571;
  font-size: 20px;
}

.prepaidCardButtonPurchase {
  border: 1px solid #001571;
  padding: 15px;
  border-radius: 8px;
  background: #001571;
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.prepaidCardButtonPurchase:hover {
  color: #001571;
  background-color: white;
}

.userRego {
  margin-bottom: 30px;
  width: 60%;
}

.inputsSection.locationDetailSectionOffline {
  opacity: 0.5;
}

.prepaidUserDetails {
  margin-bottom: 30px;
}

.prepaidCardCode h3,
.prepaidCardCode p {
  text-align: center;
}

.prepaidCardCode p {
  width: 300px;
  margin: auto;
  margin-top: 25px;
}
.titleSection h1 {
  font-weight: 600;
}

.noteSection {
  max-width: 400px;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}
