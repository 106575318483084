body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

.lightBlueButton{
  background-color: #00A0D2;
  padding: 12px 0;
  width: 175px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  border: 1px solid #00A0D2;

}

.blueBorderButton{
  border: 1px solid #001571;
  padding: 12px 0;
  width: 175px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 40px;
  color: #001571;
  cursor: pointer;
  background-color: white;
}

button.lightBlueButton, button.lightBlueButton:hover{
  background-color: #00A0D2;
  padding: 12px 0;
  font-weight: 600;
  font-size: 16px;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  border: 1px solid #00A0D2;
}

button.blueBorderButton{
  border: 1px solid #001571;
  padding: 12px 0;
  width: 175px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 40px;
  color: #001571;
  cursor: pointer;
  background-color: white;
}

.LoadingSection{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 75vh;
  position: relative;
  padding-top: 50px;
}

.bookingFlowSection {
  min-height: 75vh;
}

h1, h2, h3, h4, h5, h6, p, a, button{
  font-family: 'Poppins', sans-serif;
}

.popupModal .MuiBox-root::-webkit-scrollbar{
  display: none;
}
  
.popupModal .MuiBox-root::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.flexRow{
  display: flex;
  flex-direction: row;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

input{
  background: #F8F8F8;
  border-radius: 4px;
  border: none;
  height: 44px;
  padding: 0 15px
}

input::placeholder{
  color: #757575;
  font-weight: 400;
  font-family: Poppins;
}
