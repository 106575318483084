/* General Container */
.resumeBooking {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.resumeContent{
  padding-left: 50px;
  padding-right: 50px;
}

.resumeBooking .confirmationHeadings{
  font-size: large;
  color: #001571;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: left;
}
  
  /* Section Headers */
  .resumeBooking h3 {
    font-size: large;
    color: #001571;
    margin-bottom: 10px;
  }
  
  /* Notes and Small Text */
  .resumeBooking .note {
    font-size: 0.9rem;
    color: #999;
    margin-top: 5px;
  }
  
  /* Email Input Section */
  .emailInputSection {
    margin-bottom: 20px;
  }
  
  /* Vehicle Options */
  .serviceOptions {
    display: flex;
    gap: 15px;
  }
  
  .serviceOptions .vehicle-option {
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    width: 120px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .serviceOptions .vehicle-option img {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
  }
  
  .serviceOptions .vehicle-option.selected {
    border-color: #00a0d2;
    background: #e6f7ff;
  }
  
  /* Booking Time Section */
  .bookingTimeSection .bookingTimeButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .bookingTimeSection img.calendarIco {
    width: 20px;
    height: 20px;
  }
  
  /* Complete Booking Button */
  .completeBookingButton {
    margin-top: 20px;
    background-color: #00a0d2;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: center;
  }

  .errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #d9534f;
  font-weight: bold;
  text-align: center;
  background-color: #f9f9f9;
}

.errorMessage div {
  text-align: center; /* Ensures all content inside is centered */
}

.newBookingButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00a0d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.serviceTypeSection, .vehicleTypeSection{
    margin-top: 50px;
}

.cardOptionImage{
    -webkit-filter: sepia(0) saturate(0) brightness(0) hue-rotate(180deg);
    filter: sepia(0) saturate(0) brightness(0) hue-rotate(180deg);
    margin-bottom: 0;
    opacity: .3;
}

.activeCard .cardOptionImage{ 
    opacity: 1;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* 40% black opacity */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center; /* Center content inside the overlay */
  }

  .continueLogo img{
    width: 80%;
  }

  .resumeBooking h1{
    margin-top: 30px;
    font-size: x-large;
  }

  .updatedLeftAlign{
    padding: 20px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .UpdatedHeading{
    width: 100%;
  }

  .updatedLeftAlign .UpdatedHeading h2, .updatedLeftAlign p{
    text-align: left;
    width: 100%;
  }

  .resumeBooking .bookingPriceGrid{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .resumeBooking .bookingPriceGrid .pricingCardPOA{
    margin-top: 20px;
    font-size: larger;
    color: #001571;
  }

  .resumeBooking .includedItemsList{
    width: 100%;
    padding-left: 20px;
  }

  .bottomCardSection{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
  }

  .bottomCardSection .bookingPriceSelect{
    margin: 0;
    width: 100%;
  }

  .bookingPriceGrid .pricingCard .bookNowButton{
    margin-top: 15px;
    width: 100%;
  }




  @media (max-width: 767px) {
    .resumeContent{
      padding-left: 0;
      padding-right: 0;
    }
  }